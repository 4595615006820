var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "branch-container parametrizacao" },
        [
          _vm.lastLog
            ? _c(
                "div",
                { staticClass: "history" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "pl-3 bold",
                      staticStyle: {
                        "margin-right": "5px",
                        "font-size": "12px",
                      },
                    },
                    [
                      _vm._v(
                        "\n        Última alteração em " +
                          _vm._s(_vm.$utils.dateToDDMMYYYY(_vm.lastLog.Data)) +
                          " por\n        " +
                          _vm._s(_vm.lastLog.NomeUsuario) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { round: "" },
                      on: {
                        click: function ($event) {
                          _vm.popupHistoricoAlteracoes = true
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "onpoint-clock",
                        staticStyle: { position: "relative", top: "2px" },
                      }),
                      _vm._v(" Histórico de alterações"),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-row",
            [
              _c("vs-col", { staticClass: "title" }, [
                _c(
                  "span",
                  {
                    staticClass: "pl-3 bold",
                    staticStyle: { color: "#636363", "font-size": "19px" },
                  },
                  [_vm._v("\n          Cadastrados\n        ")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticStyle: { "margin-top": "0px" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "endosso-list",
                  attrs: { "vs-sm": "12", "vs-lg": "12" },
                },
                [
                  _c("div", { staticClass: "card-button" }, [
                    _c("div", {
                      staticClass: "ghost-top",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goList()
                        },
                      },
                    }),
                    _c("div", {
                      staticClass: "ghost",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goList()
                        },
                      },
                    }),
                    _c("div", { staticClass: "head" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("i", {
                          staticClass: "onpoint-files",
                          staticStyle: { "font-size": "30px" },
                        }),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button-config",
                            on: {
                              click: function ($event) {
                                return _vm.goConfig()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "onpoint-gear",
                              staticStyle: { "font-size": "16px" },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "footer text-left" }, [
                      _c("b", [_vm._v(" Endosso de Aniversário")]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "history-change",
          attrs: {
            visible: _vm.popupHistoricoAlteracoes,
            "close-on-click-modal": "",
            "modal-append-to-body": "",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.popupHistoricoAlteracoes = $event
            },
          },
        },
        [
          _c("div", { staticClass: "pop-up-content" }, [
            _c("div", { staticClass: "pop-up-content-header" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/icons/ccg-icons/history-icon-ccg.svg"),
                  alt: "Ícone de excluir representante",
                  width: "24",
                  height: "24",
                },
              }),
              _c("h3", [_vm._v("Histórico de alterações")]),
            ]),
            _c("div", { staticClass: "pop-up-content-comparation" }, [
              _c("div", { staticClass: "pop-up-content-comparation-header" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-data" },
                  [_vm._v("\n            Dados\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider",
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-before" },
                  [_vm._v("\n            Antes\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider",
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-after" },
                  [_vm._v("\n            Depois\n          ")]
                ),
              ]),
              _c(
                "ul",
                { staticClass: "pop-up-content-comparation-items" },
                _vm._l(_vm.logs, function (alteracao, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "pop-up-content-comparation-item",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pop-up-content-comparation-item-data" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Data:\n                "
                                  ),
                                ]
                              ),
                              alteracao.Data
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pop-up-content-comparation-item-info",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$utils.dateToDDMMYYYY(
                                              alteracao.Data
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Usuário:\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(alteracao.NomeUsuario) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Ação:\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(alteracao.Acao) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", {
                        staticClass: "pop-up-content-comparation-item-divider",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-before",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info",
                                },
                                [
                                  alteracao.Antes.length > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-view",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showItens(
                                                alteracao.Antes
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Visualizar")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pop-up-content-comparation-item-divider-arrow",
                        },
                        [_c("i", { staticClass: "el-icon-right" })]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-after",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  alteracao.Depois.length > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-view",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showItens(
                                                alteracao.Depois
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Visualizar")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer pop-up-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.popupHistoricoAlteracoes = false
                    },
                  },
                },
                [_vm._v("Fechar")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }