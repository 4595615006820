<template>
  <div>
    <div class="branch-container parametrizacao">
      <div class="history" v-if="lastLog">
        <span class="pl-3 bold" style="margin-right: 5px; font-size: 12px;">
          Última alteração em {{ $utils.dateToDDMMYYYY(lastLog.Data) }} por
          {{ lastLog.NomeUsuario }}
        </span>
        <el-button round @click="popupHistoricoAlteracoes = true"
          ><i class="onpoint-clock" style="position: relative;top:2px;"></i> Histórico de alterações</el-button
        >
      </div>
      <vs-row>
        <vs-col class="title">
          <span class="pl-3 bold" style="color: #636363; font-size: 19px;">
            Cadastrados
          </span>
        </vs-col>
      </vs-row>

      <vs-row style="margin-top: 0px">
        <vs-col class="endosso-list" vs-sm="12" vs-lg="12">
          <div class="card-button">
            <div class="ghost-top" @click.prevent="goList()"></div>
            <div class="ghost" @click.prevent="goList()"></div>
            <div class="head">
              <div class="left">               
                <i class="onpoint-files" style='font-size:30px'></i>
              </div>
              <div class="right">
                <button class="button-config" @click="goConfig()">
                  <i class="onpoint-gear" style='font-size:16px;' ></i>                  
                </button>                
              </div>
            </div>
            <div class="footer text-left">
              <b> Endosso de Aniversário</b>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <!-- Pop up Histórico de alterações -->
    <el-dialog
      :visible.sync="popupHistoricoAlteracoes"
      close-on-click-modal
      modal-append-to-body
      center
      class="history-change"
    >
      <div class="pop-up-content">
        <div class="pop-up-content-header">
          <img
            src="@/assets/images/icons/ccg-icons/history-icon-ccg.svg"
            alt="Ícone de excluir representante"
            width="24"
            height="24"
          />
          <h3>Histórico de alterações</h3>
        </div>

        <div class="pop-up-content-comparation">
          <div class="pop-up-content-comparation-header">
            <div class="pop-up-content-comparation-header-data">
              Dados
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-before">
              Antes
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-after">
              Depois
            </div>
          </div>
          <ul class="pop-up-content-comparation-items">
            <li
              class="pop-up-content-comparation-item"
              v-for="(alteracao, index) in logs"
              :key="index"
            >
              <div class="pop-up-content-comparation-item-data">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Data:
                  </p>
                  <p
                    class="pop-up-content-comparation-item-info"
                    v-if="alteracao.Data"
                  >
                    {{ $utils.dateToDDMMYYYY(alteracao.Data) }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Usuário:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.NomeUsuario }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Ação:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Acao }}
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider"></div>

              <div class="pop-up-content-comparation-item-before">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-info">
                    <el-button
                      v-if="alteracao.Antes.length > 0"
                      type="primary"
                      icon="el-icon-view"
                      circle
                      @click="showItens(alteracao.Antes)"
                      >Visualizar</el-button
                    >
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider-arrow">
                <i class="el-icon-right"></i>
              </div>

              <div class="pop-up-content-comparation-item-after">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    <el-button
                      v-if="alteracao.Depois.length > 0"
                      type="primary"
                      icon="el-icon-view"
                      circle
                      @click="showItens(alteracao.Depois)"
                      >Visualizar</el-button
                    >
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div slot="footer" class="dialog-footer pop-up-footer">
        <el-button type="primary" @click="popupHistoricoAlteracoes = false"
          >Fechar</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import instance from "@/axios";
export default {
  components: {},
  data() {
    return {
      popupHistoricoAlteracoes: false,
      logs: {},
      lastLog: false
    };
  },
  async mounted() {
    await this.ObterLogAuditoria();
  },
  methods: {
    async ObterLogAuditoria() {
      return instance({
        method: "get",
        url: "/api/Parametros/ObterLogAuditoriaEndossoAniversario"
      }).then(response => {
        this.logs = response.data.Response;
        this.lastLog = response.data.Response.slice(-1).pop();
      });
    },
    goConfig() {
      this.$router.push({
        name: "param-configurar-endosso"
      });
    },
    goList() {
      this.$router.push({
        name: "param-list-endosso"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.branch-container {
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 25px;

  .title {
    padding: 32px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    /* grayscale/gray-4 */

    color: #8e8e8e;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .modality-group-content:not(:last-of-type) {
    border-bottom: 1px solid #e4e1e1;
  }

  .modality-group-content:not(:first-of-type) {
    padding-top: 16px;
  }

  .modality-group-content {
    margin: 0 16px 16px;
    padding-bottom: 16px;

    .modality-group-title {
      justify-content: space-between;
      padding-bottom: 28px;
      padding-top: 0px;
    }

    .data-content {
      color: #50555a;
      font-size: 14px;
      font-weight: 700;
    }

    .data-title {
      color: #8e8e8e;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.card-gray {
  background: #f7f7f7;
  color: #636363;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #63636321;
  padding: 25px 15px;
  display: flex;

  .left,
  .right {
    width: 50%;
  }

  .left {
    text-align: left;
  }

  .right {
    display: flex;
    flex-direction: row-reverse;
  }
}

.main-card {
  padding: 15px 25px;
}

.history {
  position: absolute;
  top: -65px;
  right: 0;
}
.endosso-list {
  margin-bottom: 50px;
  padding-left: 25px;
  .card-button {
    position: relative;
    width: 340px;
    background: #f9fafb;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
    padding: 20px 25px;
    cursor: pointer;
    .ghost{
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 73%;
      inset: auto 0px 0px 0px;
      border-radius: 10px;
    }
    .ghost-top{
      position: absolute;
      background-color: transparent;
      width: 80%;
      height: 27%;
      inset: 0px 0px 0px 0px;
      z-index: 9;
    }
    .head {
      display: flex;
      margin-bottom: 140px;
      .left {
        width: 50%;
        padding-top: 5px;
        text-align: left;
      }
      .right {
        width: 50%;
        text-align: right;
      }
    }
  }
  .card-button:hover {
    box-shadow: 10px 10px 5px 0px rgba(var(--vs-primary), 0.45);
  }
}
</style>

<style lang="scss">
.vs-popup {
  width: auto !important;
}
.addtag {
  float: right;
  border-radius: 0px !important;
}
</style>

<style lang="scss" scoped>
.btn-view-changes {
  height: fit-content;
  padding: 10px 24px 10px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;

  &:hover {
    background: rgba(var(--vs-primary), 0.8) !important;
  }
}

.el-button.is-disabled {
  background: rgba(var(--vs-primary), 0.8) !important;
  pointer-events: none;
}

.pop-up-content {
  &-header {
    width: 100%;
    text-align: center;
    color: rgba(var(--vs-primary), 1);
    padding-bottom: 16px;
    border-bottom: 1px solid #747474;

    & i {
      width: 20px;
      height: 20px;

      &::before {
        font-size: 20px;
      }
    }

    & h3 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  &-comparation {
    padding: 36px 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-header {
      width: 100%;
      display: flex;
      padding: 0 16px 0 0;

      &-before,
      &-after,
      &-divider,
      &-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-divider {
        margin: 0 18px;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
        height: 50px;
        border-radius: 8px 8px 0 0;
        font-size: 18px;
        font-weight: 700;
        color: rgba(13, 27, 51, 1);
      }

      &-data {
        background-color: rgba(236, 236, 236, 1);
      }

      &-before {
        background-color: rgba(236, 236, 236, 1);
      }

      &-after {
        background-color: rgba(174, 255, 192, 1);
      }
    }

    &-items {
      height: 450px;
      overflow: auto;
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 36px 0;
      border-bottom: 1px solid rgba(116, 116, 116, 1);

      &-before,
      &-after,
      &-divider,
      &-divider-arrow,
      &-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
      }

      &-divider {
        margin: 0 18px;
      }

      &-divider-arrow {
        & i {
          width: 36px;
          height: 36px;

          &::before {
            font-size: 36px;
            color: rgba(236, 236, 236, 1) !important;
          }
        }
      }

      &-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-title {
        width: 50%;
        text-align: end;
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        word-break: break-word;
      }

      &-info {
        width: 50%;
        margin-left: 8px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(13, 27, 51, 1);
        word-break: break-word;
      }
    }

    &-item:last-child {
      border-bottom: none;
    }
  }
}

.pop-up-footer {
  padding: 36px 36px;
  border-top: 1px solid rgba(116, 116, 116, 1);
}

.button-config{
  padding:9px; 
  border-radius:50px;
  border: 1px solid;
  border-color: grey;
  background-color: #f0f0f0;
  &:hover {
    cursor:pointer;
    background-color: #93c8f5;
    color: #7582ba;
  }
}
</style>
